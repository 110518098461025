<template>
  <v-app-bar dense :extended="$vuetify.breakpoint.xsOnly" fixed>
    <form-navigator :id="id"></form-navigator>
    <template v-if="!$vuetify.breakpoint.xsOnly">
      <page-panel compact="true" class="col"></page-panel>
      <information-banner class="ml-auto"></information-banner>
    </template>
    <template v-slot:extension v-if="$vuetify.breakpoint.xsOnly">
      <page-panel compact="true" class="col"></page-panel>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import InformationBanner from "./patient/InformationBanner.vue";
import PagePanel from "./form/PagePanel.vue";
import FormNavigator from "../components/FormNavigator";

export default {
  props: ["id"],
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    isPatient() {
      return this.isInRole("patient");
    }
  },
  components: { InformationBanner, PagePanel, FormNavigator }
};
</script>
