<template>
  <div class="d-flex align-center">
    <patient-navigator v-if="isPatient"></patient-navigator>
    <pageSet-picker :id="id" v-else></pageSet-picker>
    <edit-page-set-button
      v-if="isStudioMode && displayStudio && !isHomePage"
      class="ml-2"
    ></edit-page-set-button>
    <delete-pageset-button
      v-if="isStudioMode && displayStudio && !isHomePage"
      class="ml-2"
    ></delete-pageset-button>
    <v-divider
      v-if="isStudioMode && displayStudio"
      vertical
      class="ml-2"
    ></v-divider>
    <insert-page-set-button
      v-if="isStudioMode && displayStudio"
      class="ml-2"
    ></insert-page-set-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageSetPicker from "./form/PageSetPicker.vue";
import PatientNavigator from "./patient/PatientNavigator.vue";
import EditPageSetButton from "../components/studio/EditPageSetButton.vue";
import DeletePagesetButton from "../components/studio/DeletePagesetButton.vue";
import InsertPageSetButton from "../components/studio/InsertPageSetButton.vue";
import workflows from "@/mixin/workflows.js";

export default {
  props: ["displayStudio", "id"],
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    isPatient() {
      return this.isInRole("patient");
    }
  },
  mixins: [workflows],
  components: {
    PageSetPicker,
    PatientNavigator,
    EditPageSetButton,
    InsertPageSetButton,
    DeletePagesetButton
  }
};
</script>
