<template>
  <div v-if="included">
    <resend-banner
      v-if="hasContact"
      :message="$t('urlMessage')"
      :label="$t('resend')"
      color="info"
      @resend="notify()"
    >
    </resend-banner>
    <div v-else>
      <resend-banner
        :message="$t('magicMessage')"
        :label="$t('regenerate')"
        color="success"
        @resend="regenerate()"
      >
        <qr-code :url="url"></qr-code>
      </resend-banner>
    </div>
  </div>
</template>

<script>
import ResendBanner from "../tools/ResendBanner.vue";
import generator from "generate-password";
import QrCode from "../tools/QrCode.vue";
import { User } from "spiral";
import { mapGetters } from "vuex";

export default {
  data() {
    return { url: undefined };
  },
  components: { ResendBanner, QrCode },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    included() {
      return this.currentPatient.included || this.isInRole("patient");
    },
    patientUser() {
      return new User(this.currentStudy, this.currentPatient);
    },
    hasContact() {
      return this.patientUser.email || this.patientUser.phone;
    }
  },
  methods: {
    notify: function() {
      const baseUrl = `notification/${this.currentStudy.name}/patient/create/${this.currentPatient.patientCode}`;
      this.drivers.client.post(baseUrl);
    },
    regenerate: async function() {
      const sites = await this.drivers.siteDriver.getAll(this.currentStudy);
      const user = await this.drivers.userDriver.getByUserId(
        this.currentStudy,
        sites,
        this.patientUser.userid
      );
      const regenerated = await this.drivers.userDriver.save(
        this.currentStudy,
        user.update({
          password: generator.generate({ length: 16, numbers: true })
        })
      );
      this.url = `${window.location.origin}/${this.currentStudy.name}/epro/patient/${this.currentPatient.patientCode}/form?m=${regenerated.magic}`;
    }
  },
  watch: {
    included(val, old) {
      if (!old && val) {
        if (this.hasContact) this.notify();
        else this.regenerate();
      }
    },
    hasContact(val, old) {
      if (!old && val) this.notify();
      else this.regenerate();
    }
  },
  i18n: {
    messages: {
      en: {
        urlMessage: "Connection url has been sent",
        resend: "resend",
        magicMessage: "Connection url is given by QR Code",
        regenerate: "regenerate"
      },
      fr: {
        urlMessage: "L'url de connexion a été envoyée",
        resend: "renvoyer",
        magicMessage: "L'url de connexion est transmise par QR code",
        regenerate: "regénérer"
      }
    }
  }
};
</script>
