<template>
  <div v-if="display">
    <v-card no-body v-if="currentPageSet.pages">
      <v-card-title
        :class="{
          'd-flex justify-space-between align-baseline': !mobile,
          'pb-0': mobile
        }"
      >
        <div>Signature</div>
        <resend-banner
          v-if="!signed && canSign"
          :message="$t('codeInfo')"
          :label="$t('resend')"
          color="warning"
          @resend="resend()"
        >
        </resend-banner>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap align-content-end">
          <div v-if="failure" class="text-center mr-10">
            <v-alert
              dense
              border="bottom"
              type="error"
              elevation="7"
              class="text-h5"
              icon="mdi-alert-octagon"
            >
              Failed
            </v-alert>
          </div>
          <div v-if="signed" class="text-center mr-10">
            <v-alert
              dense
              border="bottom"
              type="success"
              elevation="7"
              class="text-h5"
              icon="mdi-check-all"
            >
              Signed
            </v-alert>
            {{ signature.userid }}
          </div>
          <div class="flex-grow-1">
            <v-text-field
              prepend-icon="mdi-calendar"
              :value="date.toLocaleString('sv')"
              disabled
            >
            </v-text-field>
          </div>
        </div>
        <div v-if="!signed && canSign">
          <lang-label :labels="i18nToLabels($i18n, 'code')"> </lang-label>
          <value-input
            name="__SIGNATURE_CODE"
            :value="signature.code"
            :type="{ name: 'text' }"
            @input="onInput($event)"
          >
          </value-input>
          <div class="text-right">
            <v-btn
              v-if="!signature.verified"
              small
              color="secondary"
              @click="sign()"
            >
              {{ $t("sign") }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <div v-if="signed">
        {{ signature.content }}
        {{ signature.hash }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { Signature } from "spiral";
import ValueInput from "../input/ValueInput";
import LangLabel from "../input/LangLabel.vue";
import ResendBanner from "../tools/ResendBanner.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      enableSigning: false,
      failure: false,
      code: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    date() {
      return this.signature.record?.utcDate
        ? new Date(this.signature.record?.utcDate)
        : new Date(this.signature.date);
    },
    fullName() {
      return this.signature.record?.fullName;
    },
    meaning() {
      return this.signature.record?.meaning;
    },
    display() {
      return (
        this.signature &&
        this.isSignaturePage &&
        ((this.isSignatory && this.canSign) || this.signed) &&
        !this.isStudioMode &&
        this.currentPage &&
        this.currentPage.items.length > 0
      );
    },
    signature() {
      return this.currentInterview
        ?.availableProcesses(this.currentWorkflow)
        ?.find(p => p.action == "signature");
    },
    canSign() {
      const hasNonce = !!this.currentInterview?.nonce;
      return (
        hasNonce && this.authorizationManager.canSign(this.currentInterview)
      );
    },
    isSignaturePage() {
      return this.currentPage == this.currentPageSet.pages.last;
    },
    signed() {
      return this.signature?.verified;
    },
    isSignatory() {
      return this.isInRole("investigator", "patient", "developer");
    }
  },
  methods: {
    isValidCode(code) {
      return /[0-9]{6}/.test(code);
    },
    onInput(val) {
      this.enableSigning = this.isValidCode(val) && val != this.signature.code;
      if (this.enableSigning) {
        this.code = val;
      }
    },
    async resend() {
      await this.pushSaveProcesses([this.signature.reset()]);
    },
    async sign() {
      const signed = this.signature.verify(this.code.trim());
      await this.pushSaveProcesses([signed]).then(() => {
        this.failure = !this.signature.verified;
      });
    },
    async initSignature() {
      if (this.canSign && this.signature == undefined)
        await this.pushSaveProcesses([new Signature()]);
    }
  },
  async mounted() {
    await this.initSignature();
  },
  watch: {
    async canSign() {
      await this.initSignature();
      if (this.canSign && this.currentInterview.status == "fulfilled")
        this.$el.scrollIntoView({ behavior: "smooth" });
    }
  },
  components: {
    ValueInput,
    LangLabel,
    ResendBanner
  },
  i18n: {
    messages: {
      en: {
        date: "Date",
        codeInfo: "A signature code has been sent so you can sign this form",
        code: "Please enter the code you received",
        resend: "Resend a code",
        sign: "Sign"
      },
      fr: {
        date: "Date",
        codeInfo:
          "Un code de signature vous a été envoyé afin de signer ce formulaire",
        code: "Veuillez entrer le code que vous avez reçu",
        resend: "Renvoyer un code",
        sign: "Signer"
      }
    }
  }
};
</script>
