<template>
  <div>
    <span class="backAction">
      <v-btn
        v-if="canReturnToPatientList"
        id="backToPatientList"
        class="ml-3 my-1"
        color="primary"
        @click="goToPatientList"
        small
      >
        <v-icon>mdi-chevron-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <template v-else>
        <v-btn
          v-if="previousIndex != indexMax"
          id="previous"
          class="ml-3 my-1"
          @click="onPrevious"
          color="primary"
          small
        >
          <v-icon>mdi-chevron-left</v-icon>
          {{ $t("action.previous") }}
        </v-btn>
      </template>
    </span>
    <template v-if="canContinue">
      <v-btn
        id="save"
        @click="onContinue"
        class="ml-3 my-1"
        color="primary"
        :disabled="saveDisabled"
        small
      >
        {{ $t("action.continue") }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["index", "indexMax", "saveDisabled"],
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    nextIndex() {
      return this.index == this.indexMax ? 0 : this.index + 1;
    },
    previousIndex() {
      return this.index == 0 ? this.indexMax : this.index - 1;
    },
    canReturnToPatientList() {
      return (
        this.previousIndex == this.indexMax &&
        this.isInRole(
          "developer",
          "administrator",
          "investigator",
          "studycoordinator",
          "cst",
          "cra",
          "datamanager",
          "dataadministrator",
          "deo"
        )
      );
    },
    canContinue() {
      return this.nextIndex > 0 && this.indexMax > 0 && !this.saveDisabled;
    }
  },
  methods: {
    onPrevious() {
      this.$emit("action", { index: this.previousIndex });
    },
    onContinue() {
      this.$emit("action", { index: this.nextIndex });
    },
    goToPatientList() {
      this.$router.push({ name: "Search patient" });
    }
  },
  watch: {
    canContinue(val) {
      if (!this.isStudioMode && val)
        this.$el.scrollIntoView({ behavior: "smooth" });
    }
  },
  i18n: {
    messages: {
      en: {
        back: "Back to the Patient List"
      },
      fr: {
        back: "Retour à la liste des patients"
      }
    }
  }
};
</script>
