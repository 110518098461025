<template>
  <div>
    <div v-if="!isIntersecting">
      <summary-bar id="compact"></summary-bar>
    </div>
    <v-row>
      <v-col
        cols="12"
        class="d-md-inline-flex justify-md-space-between align-center flex-wrap"
        :class="{ 'pb-0': mobile }"
      >
        <div
          id="nav-intersect"
          v-intersect="onIntersect"
          class="d-md-inline-flex align-baseline"
        >
          <form-navigator :displayStudio="true" id="extended"></form-navigator>
          <last-save-date
            v-if="
              !$vuetify.breakpoint.mdAndDown &&
                !this.isInRole('patient') &&
                !isStudioMode
            "
            class="ml-2"
          ></last-save-date>
          <toggle-visible v-if="isStudioMode" class="mx-5"></toggle-visible>
        </div>
        <information-banner v-if="isIntersecting"></information-banner>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <pageSet-viewer>
          <template v-slot:footer>
            <v-col cols="12" :class="{ 'px-0': mobile }">
              <form-signature></form-signature>
            </v-col>
          </template>
        </pageSet-viewer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageSetViewer from "../components/form/PageSetViewer";
import InformationBanner from "../components/patient/InformationBanner";
import SummaryBar from "../components/SummaryBar";
import FormSignature from "../components/form/FormSignature";
import FormNavigator from "../components/FormNavigator";
import LastSaveDate from "../components/form/LastSaveDate.vue";
import ToggleVisible from "../components/studio/ToggleVisible.vue";

export default {
  name: "PatientForm",
  data() {
    return {
      isIntersecting: true
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    isPatient() {
      return this.isInRole("patient");
    }
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    }
  },
  components: {
    FormNavigator,
    PageSetViewer,
    InformationBanner,
    SummaryBar,
    FormSignature,
    LastSaveDate,
    ToggleVisible
  }
};
</script>

<style>
#nav-intersect {
  min-height: 32px;
}
</style>
