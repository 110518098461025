<template>
  <div v-if="display" class="col-12 px-0">
    <div class="d-flex justify-space-between">
      <h4>{{ $t("studyOptions") }}</h4>
      <v-btn @click="display = false" icon id="hide-study-options"
        ><v-icon>mdi-eye-off</v-icon></v-btn
      >
    </div>
    <v-divider class="mt-0"></v-divider>
    <v-alert
      border="left"
      color="info"
      colored-border
      elevation="1"
      class="px-10"
    >
      <div class="d-flex justify-end">
        <edit-study-options-button
          class="mx-4"
          @updated="loadOptions()"
        ></edit-study-options-button>
      </div>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="20"
        hide-default-footer
      ></v-data-table>
    </v-alert>
  </div>
  <div v-else class="d-flex flex-wrap mr-2">
    <v-btn @click="display = true" color="info" outlined id="show-study-options"
      ><v-icon left>mdi-eye</v-icon>{{ $t("studyOptions") }}</v-btn
    >
  </div>
</template>

<script>
import EditStudyOptionsButton from "./EditStudyOptionsButton.vue";
export default {
  components: { EditStudyOptionsButton },
  data() {
    return {
      display: false,
      studyOptions: undefined
    };
  },
  computed: {
    headers() {
      return [
        { text: "Option", value: "option" },
        { text: this.$t("value"), value: "value" }
      ];
    },
    items() {
      return Object.keys(this.studyOptions)
        ?.filter(k => k != "workflowVar")
        .map(k => ({
          option: k,
          value:
            this.studyOptions?.[k]?.name ??
            (typeof this.studyOptions?.[k] == "string"
              ? this.studyOptions?.[k]
              : JSON.stringify(this.studyOptions?.[k]))
        }));
    }
  },
  methods: {
    loadOptions() {
      this.studyOptions = this.currentStudy.options;
    }
  },
  mounted() {
    this.loadOptions();
  },
  i18n: {
    messages: {
      en: {
        studyOptions: "Study Options",
        value: "Value"
      },
      fr: {
        studyOptions: "Options de l'étude",
        value: "Valeur"
      }
    }
  }
};
</script>
