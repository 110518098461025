<template>
  <div>
    <v-dialog v-if="isComplete" value="true" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("thanks") }}
        </v-card-title>

        <v-card-text class="d-flex flex-column">
          <div class="d-inline-flex align-center">
            <div class="col mr-3">{{ text }}</div>
            <print-consent
              v-if="consent"
              :patient="currentPatient"
              :compact="true"
            ></print-consent>
          </div>

          <div v-if="consent" class="ml-3 mt-2">
            <strong>{{ $t("alt1") }}</strong>
            <consent-withdrawal compact></consent-withdrawal>
          </div>

          <div class="ml-3 mt-1">
            <strong>{{ $t("alt2") }}</strong>
            <logout-control></logout-control>
          </div>

          <div v-if="next && (!isConsentPageSet || consent)" class="ml-3">
            <strong>{{ $t("alt3") }}</strong>
            <continue-countdown
              :delay="isConsentPageSet ? 30 : 10"
              @click="goNext()"
            >
            </continue-countdown>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-else-if="!isConsentPageSet && consent" class="d-flex align-center">
      <consent-withdrawal :compact="mobile"></consent-withdrawal>
      <print-consent
        :patient="currentPatient"
        :compact="mobile"
        class="mx-2"
      ></print-consent>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Interview } from "spiral";
import LogoutControl from "../user/LogoutControl.vue";
import ConsentWithdrawal from "./ConsentWithdrawal.vue";
import ContinueCountdown from "../tools/ContinueCountdown.vue";
import PrintConsent from "../tools/PrintConsent.vue";

export default {
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    availableInterviews() {
      return this.currentPatient.availableInterviews(this.currentWorkflow);
    },
    consent() {
      return this.currentPatient.consent;
    },
    signed() {
      return (
        this.currentInterview
          ?.availableProcesses(this.currentWorkflow)
          ?.some(p => p.action == "signature") ?? false
      );
    },
    text() {
      if (!this.isConsentPageSet && !this.consent)
        return this.$t("text-before-consent", {
          expiresAt: this.expiresAt.toLocaleDateString("sv")
        });
      if (this.isConsentPageSet && this.consent)
        return this.$t("text-on-consent");
      return this.$t("text-after-consent", { formType: this.formType });
    },
    isConsentPageSet() {
      return !!this.currentInterview?.consent;
    },
    isComplete() {
      if (this.currentInterview?.status != "fulfilled") return false;
      if (
        this.currentProcess?.action == "input" ||
        this.currentProcess?.action == "signature"
      )
        return (
          this.currentProcess?.status == "fulfilled" &&
          !this.currentInterview
            .availableActions(this.currentWorkflow)
            .includes("signature")
        );
      return false;
    },
    formType() {
      return this.getLabel(
        "mlstring",
        {},
        this.currentPageSet.type,
        this.$i18n
      );
    },
    next() {
      return this.currentPatient.next(
        this.currentWorkflow,
        this.currentInterview
      );
    },
    startingDate() {
      return this.currentPatient.interviews.find(
        i =>
          i.items.some(
            i =>
              typeof i.value != "undefined" &&
              (i.pageItem.variableName == this.currentStudy.options.phoneVar ||
                i.pageItem.variableName == this.currentStudy.options.emailVar)
          ) && typeof i.date != "undefined"
      )?.date;
    },
    expiresAt() {
      if (typeof this.startingDate == "undefined") return undefined;
      return new Date(this.startingDate.getTime() + 14 * 86400000);
    }
  },
  methods: {
    async goNext() {
      if (this.next instanceof Interview) this.setInterview(this.next);
      else {
        this.createInterview(this.next);
      }
    }
  },
  beforeMount() {
    this.createFirstInterviewIfNotExists();
  },
  components: {
    LogoutControl,
    ConsentWithdrawal,
    ContinueCountdown,
    PrintConsent
  },
  i18n: {
    messages: {
      en: {
        consent: "Consent",
        thanks: "Thank you,",
        subtext: "You can",
        alt1: "@:subtext withdraw your consent at any time.",
        alt2: "@:subtext disconnect and reconnect later.",
        alt3: "@:subtext wait a few seconds and click on continue.",
        "text-before-consent":
          "If you do not consent before {expiresAt}, your personal data will be automatically deleted.",
        "text-on-consent": "You can print or save your signed consent.",
        "text-after-consent":
          "You have signed {formType}, the answers are no longer editable."
      },
      fr: {
        consnet: "Consentement",
        thanks: "Merci,",
        subtext: "Vous pouvez",
        alt1: "@:subtext retirer votre consentement à tout moment.",
        alt2: "@:subtext vous déconnecter et vous reconnecter plus tard.",
        alt3: "@:subtext attendre quelques secondes et continuer.",
        "text-before-consent":
          "Si vous ne procédez pas au consentement avant {expiresAt}, vos données personnelles seront automatiquement supprimées.",
        "text-on-consent":
          "Vous pouvez imprimer ou sauvegarder votre consentement signé.",
        "text-after-consent":
          "Vous avez signé {formType}, les réponses ne sont plus modifiables."
      }
    }
  }
};
</script>
