<template>
  <print-button
    :css="css"
    :get="get"
    :title="$t('printConsent')"
    :compact="compact"
    outlined
  >
  </print-button>
</template>

<script>
import PrintButton from "./PrintButton.vue";

export default {
  components: { PrintButton },
  props: ["patient", "compact"],
  computed: {
    css() {
      return "/print/consent.css";
    },
    lang() {
      return this.currentStudy.options.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.currentStudy.options.defaultLang;
    },
    get() {
      return `print/${this.currentStudy.name}/consent/${this.patient.patientCode}?lang=${this.lang}`;
    }
  },
  i18n: {
    messages: {
      en: {
        printConsent: "Print consent"
      },
      fr: {
        printConsent: "Imprimer le consentement"
      }
    }
  }
};
</script>
